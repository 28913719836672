.Heading {
    color: rgb(255, 255, 255);
}

/* if mobile device */
@media only screen and (max-width: 979px) {
    .Side {
        position: relative;
        background: rgb(21, 21, 21);
        width: 100%;
        text-align: left;
        padding-top: 15px;
        padding-left: 20px;
        padding-right: 20px;
        float: left;
        height: auto;
    }
}

/* if desktop/laptop or large enough screen */
@media only screen and (min-width: 980px) {
    .Side {
        display: block;
        background: rgb(21, 21, 21);
        max-width: 35%;
        height: 100vh;
        text-align: left;
        padding: 60px 50px;
        float: left;
        min-width: 420px;
        position: fixed;
        margin-left: 15px;
    }
}

.Headshot {
    margin-top: 5vh;
    display: flex;
}

.Link {
    float: "left";
}