html {
  background-color: rgb(21, 21, 21);
}

/* fix viewport being cut off by the iphone notch at top of the screen */
@supports (padding:max(0px)) {
  body, header, footer {
    padding-left: min(0vmin, env(safe-area-inset-left));
    padding-right: min(0vmin, env(safe-area-insert-right));
  }
}