

/* if mobile device */
@media only screen and (max-width: 979px) {
    .SocialLinks {
        padding-top: 20px;
        left: 0;
        bottom: 20px;
        right: 0;
        text-align: center;
    }
}

/* if desktop/laptop or large enough screen */
@media only screen and (min-width: 980px) {
    .SocialLinks {
        position: absolute;
        left: 0;
        bottom: 20px;
        right: 0;
        text-align: center;
    }
}