/* if mobile device */
@media only screen and (max-width: 979px) {
    .AboutPage {
        display: flex;
        flex-direction: column;
        background-color: rgb(21, 21, 21);
    }

    .About {
        padding-top: 10px;
        margin-bottom: 25px;
        overflow: hidden;
        padding-right: 2vw;
        padding-left: 2vw;

        /* hide scrollbar for FireFox and IE */
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
}


/* if desktop/laptop or large enough screen */
@media only screen and (min-width: 980px) {
    .AboutPage {
        display: flex;
        background-color: rgb(21, 21, 21);
    }

    .About {
        padding-top: 50px;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: max(50%, 440px);
        margin-right: 50px;
        overflow: hidden;

        /* hide scrollbar for FireFox and IE */
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
}

/* hide the scrollbar on chrome and safari */
.About::-webkit-scrollbar {
    display: none;
}